@use "sass:color"; @use "sass:list"; @use "sass:math"; $icon-font-path: '~@nbcuniversal-paint/react-ui-kit-css--13thstreet/fonts/'; @import "base.default-settings"; @import "13thstreet.default-settings"; @import "base.settings"; @import "base.mixins"; @import "13thstreet.settings";
.logo {
  padding-bottom: $atom-spacing;
  padding-top: $atom-spacing;

  g {
    fill: $colors-primary-alpha;
  }
}

.logotext {
  position: absolute;
  text-indent: -9999px;
}
