@use "sass:color"; @use "sass:list"; @use "sass:math"; $icon-font-path: '~@nbcuniversal-paint/react-ui-kit-css--13thstreet/fonts/'; @import "base.default-settings"; @import "13thstreet.default-settings"; @import "base.settings"; @import "base.mixins"; @import "13thstreet.settings";
.layout {
  position: relative;
}

/* DayPicker styles */

// stylelint-disable
:global .DayPicker {
  background-color: $color-bg-day-picker;
  box-shadow: 0 0 15px 0 rgba($colors-utility-black, 0.25);
  display: inline-block;
  font-size: 1rem;
}

:global .DayPicker-wrapper {
  flex-direction: row;
  padding-bottom: 1em;
  position: relative;

  user-select: none;
}

:global .DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

:global .DayPicker-Month {
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  margin: 0 1em;
  margin-top: 1em;

  user-select: none;
}

:global .DayPicker-NavBar {
}

:global .DayPicker-NavButton {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  color: $text-color-inverted;
  cursor: pointer;

  display: inline-block;
  height: 1.25em;
  left: auto;
  margin-top: 2px;
  position: absolute;
  right: 1.5em;
  top: 1em;
  width: 1.25em;
}

:global .DayPicker-NavButton:hover {
  opacity: 0.8;
}

:global .DayPicker-NavButton--prev {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
  margin-right: 1.5em;
}

:global .DayPicker-NavButton--next {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}

:global .DayPicker-NavButton--interactionDisabled {
  display: none;
}

:global .DayPicker-Caption {
  color: $text-color-inverted;
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: left;
}

:global .DayPicker-Caption > div {
  font-size: 1.15em;
  font-weight: 500;
}

:global .DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

:global .DayPicker-WeekdaysRow {
  display: table-row;
}

:global .DayPicker-Weekday {
  color: $text-color-inverted;
  display: table-cell;
  font-size: 0.875em;
  padding: 0.5em;
  text-align: center;
}

:global .DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

:global .DayPicker-Body {
  display: table-row-group;
}

:global .DayPicker-Week {
  display: table-row;
}

:global .DayPicker-Day {
  border-radius: 50%;
  color: $text-color-inverted;
  cursor: pointer;
  display: table-cell;
  padding: 0.5em;
  text-align: center;
  vertical-align: middle;
}

:global .DayPicker-WeekNumber {
  border-right: 1px solid #eaecec;
  color: $text-color-inverted;
  cursor: pointer;
  display: table-cell;
  font-size: 0.75em;
  min-width: 1em;
  padding: 0.5em;
  text-align: right;
  vertical-align: middle;
}

:global .DayPicker--interactionDisabled :global .DayPicker-Day {
  cursor: default;
}

:global .DayPicker-Footer {
  padding-top: 0.5em;
}

:global .DayPicker-TodayButton {
  background-color: transparent;
  background-image: none;
  border: none;
  box-shadow: none;
  color: $colors-primary-alpha;
  cursor: pointer;
  font-size: 0.875em;
}

/* Default modifiers */

:global .DayPicker-Day--today {
  background-color: $colors-primary-alpha;
  font-family: $font-family-bold;
  color: $colors-utility-white;
}

:global .DayPicker-Day--outside {
  color: #8b9898;
  cursor: default;
}

:global .DayPicker-Day--disabled {
  color: $text-color-inverted;
  cursor: default;
}

/* Example modifiers */

:global .DayPicker-Day--sunday {
  background-color: $text-color-inverted;
}

:global .DayPicker-Day--sunday:not(:global .DayPicker-Day--today) {
  color: $text-color-inverted;
}

:global
  .DayPicker-Day--selected:not(:global .DayPicker-Day--disabled):not(:global
    .DayPicker-Day--outside) {
  background-color: #4a90e2;
  color: $text-color-inverted;
  position: relative;
}

:global
  .DayPicker-Day--selected:not(:global .DayPicker-Day--disabled):not(:global
    .DayPicker-Day--outside):hover {
  background-color: #51a0fa;
}

:global
  .DayPicker:not(:global .DayPicker--interactionDisabled)
  :global
  .DayPicker-Day:not(:global .DayPicker-Day--disabled):not(:global
    .DayPicker-Day--selected):not(:global .DayPicker-Day--outside):hover {
  background-color: #f0f8ff;
}

/* DayPickerInput */

:global .DayPickerInput {
  display: inline-block;
}

:global .DayPickerInput-OverlayWrapper {
  position: relative;
}

:global .DayPickerInput-Overlay {
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  left: 0;
  position: absolute;
  z-index: 1;
}
