@use "sass:color"; @use "sass:list"; @use "sass:math"; $icon-font-path: '~@nbcuniversal-paint/react-ui-kit-css--13thstreet/fonts/'; @import "base.default-settings"; @import "13thstreet.default-settings"; @import "base.settings"; @import "base.mixins"; @import "13thstreet.settings";
.grid {
  @include grid-default(1);
  margin-top: $default-spacing;

  @include breakpoint($mobile-landscape) {
    @include grid-default(2);
  }

  @include breakpoint($tablet) {
    @include grid-default(3);
  }

  @include breakpoint($below-desktop) {
    @include grid-default(4);
  }
}

.cardwrapper {
  border: 1px solid $text-color;
  color: $text-color;
  text-align: left;
  text-decoration: none;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.layout {
  display: grid;

  /* autoprefixer: ignore next */
  grid-auto-rows: max-content;
  grid-gap: 0;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -ms-grid-rows: max-content;
  grid-template-areas: 'title' 'subtitle';
  grid-template-columns: auto;
  padding: 0 $atom-spacing;
}

.content {
  padding: 0 0 $default-spacing;
}

.footer {
  align-self: end;
  display: flex;
  justify-content: center;
  padding: $atom-spacing;
}

.title {
  grid-area: title;
  margin-top: 0;
}

.subtitle {
  @include vr-plumbing-set($vr-fonts, small);
  grid-area: subtitle;
  line-height: 1.5em;
  margin-bottom: $atom-spacing;

  @include breakpoint($tablet) {
    line-height: 1.5em;
  }
}

.media {
  background-color: $colors-utility-white;
  text-align: center;
}

.heading {
  text-transform: none;
}
