@use "sass:color"; @use "sass:list"; @use "sass:math"; $icon-font-path: '~@nbcuniversal-paint/react-ui-kit-css--13thstreet/fonts/'; @import "base.default-settings"; @import "13thstreet.default-settings"; @import "base.settings"; @import "base.mixins"; @import "13thstreet.settings";
.layout {
  @include grid-default(2fr 10fr, 12, $default-spacing, $default-spacing);
  margin-bottom: $default-spacing;
  padding-bottom: $default-spacing;
}

.cardtitle {
  font-family: $schedule-card-title;
  line-height: 1em;
  margin-bottom: $default-spacing;
  margin-top: 0;
  padding-top: 0;
}

.smalltext {
  @include vr-plumbing-set($vr-fonts, small);
  padding-bottom: 0;
  padding-top: 0;
}

.content {
  position: relative;
}

.onair {
  align-items: center;
  background-color: $colors-primary-alpha;
  color: $text-color-inverted;
  display: flex;
  flex-direction: column;
  float: left;
  font-size: 14px;
  justify-content: center;
  margin: $atom-spacing 0 0;
  padding: $atom-spacing;
  width: auto;
}

.meta {
  line-height: 24px;
  margin-bottom: 0;
}

.details {
  line-height: 24px;
}
